import React from 'react';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import Image from 'next/image';

export default function Custom404() {
  const { t } = useTranslation(['marketing']);

  return (
    <>
      <Head>
        <title>{t('404_title')}</title>
      </Head>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <Link href="/" className="inline-flex" legacyBehavior>
              <div>
                <span className="sr-only">Irys Teams</span>
                <Image
                  className="h-12 w-auto"
                  src="/images/irysTeams.png"
                  alt="Irys Teams"
                  width="64"
                  height="64"
                />
              </div>
            </Link>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-lg font-semibold text-gray-600 uppercase tracking-wide">
                {t('404_error')}
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                {t('page_not_found')}
              </h1>
              <p className="mt-2 text-base text-gray-500">
                {t('404_description')}
              </p>
              <div className="mt-6">
                <Link
                  href="/"
                  className="text-base font-medium text-militar-600 hover:text-militar-500"
                  legacyBehavior>
                  <div>
                    {t('go_back_home')}
                    <span aria-hidden="true"> &rarr;</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      locale,
      ...(await serverSideTranslations(locale, ['marketing'])) // Locale props
    }
  };
}
